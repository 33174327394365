import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/layout.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Layout',
		component: Layout,
		redirect: "/home",
		children: [{
				path: '/home',
				name: 'home',
				component: () => import( /* webpackChunkName: "Home" */ '../views/Home/Home.vue'),
				meta: {
					alive: true
				}
			}, {
				path: '/productList',
				name: 'productList',
				component: () => import( /* webpackChunkName: "productList" */ '../views/Home/productList.vue'),
				meta: {
					alive: true
				}
			},
			{
				path: '/sparesDetails',
				name: 'sparesDetails',
				component: () => import( /* webpackChunkName: "sparesDetails" */ '../views/sparesDetails.vue'),
				meta: {
					alive: true
				}
			},
			{
				path: '/sparesView',
				name: 'sparesView',
				component: () => import( /* webpackChunkName: "sparesView" */ '../views/sparesView.vue'),
				meta: {
					alive: true
				}
			},
			{
				path: '/examine',
				name: 'examine',
				component: () => import( /* webpackChunkName: "examine" */ '../views/examine/examine.vue'),
			},
			{
				path: '/examineDetails',
				name: 'examineDetails',
				component: () => import( /* webpackChunkName: "examineDetails" */
					'../views/examine/examineDetails.vue'),
				meta: {
					alive: true
				}
			},
			{
				path: '/classify',
				name: 'classify',
				component: () => import( /* webpackChunkName: "classify" */ '../views/classify.vue'),
				meta: {
					alive: true
				}
			},
			{
				path: '/enterStatistics',
				name: 'enterStatistics',
				component: () => import( /* webpackChunkName: "enterStatistics" */
					'../views/statistics/enterStatistics.vue')
			},
			{
				path: '/queryStatistics',
				name: 'queryStatistics',
				component: () => import( /* webpackChunkName: "queryStatistics" */
					'../views/statistics/queryStatistics.vue'),
				meta: {
					alive: true
				}
			},
			{
				path: '/queryDetails',
				name: 'queryDetails',
				component: () => import( /* webpackChunkName: "queryDetails" */
					'../views/statistics/queryDetails.vue'),
				meta: {
					alive: true
				}
			},
			{
				path: '/account',
				name: 'account',
				component: () => import( /* webpackChunkName: "account" */ '../views/account/account.vue'),
				meta: {
					alive: true
				}
			},
			{
				path: '/role',
				name: 'role',
				component: () => import( /* webpackChunkName: "account" */ '../views/role/list.vue'),
				meta: {
					alive: true
				}
			},
			{
				path: '/roleDetail',
				name: 'roleDetail',
				component: () => import( /* webpackChunkName: "account" */ '../views/role/detail.vue'),
				meta: {
					alive: true
				}
			},
			{
				path: '/user',
				name: 'user',
				component: () => import( /* webpackChunkName: "account" */ '../views/user/user.vue'),
				meta: {
					alive: true
				}
			},
			{
				path: '/reportError',
				name: 'reportError',
				component: () => import( /* webpackChunkName: "account" */ '../views/user/reportError.vue'),
				meta: {
					alive: true
				}
			},
			{
				path: '/user_log',
				name: 'userLog',
				component: () => import( /* webpackChunkName: "account" */ '../views/user/user_log.vue'),
				meta: {
					alive: true
				}
			},
			{
				path: '/order',
				name: 'order',
				component: () => import( /* webpackChunkName: "account" */ '../views/order/order.vue'),
				meta: {
					alive: true
				}
			},
			{
				path: '/accountDetails',
				name: 'accountDetails',
				component: () => import( /* webpackChunkName: "accountDetails" */
					'../views/account/accountDetails.vue'),
				meta: {
					alive: true
				}
			},
			{
				path: '/resetPassword',
				name: 'resetPassword',
				component: () => import( /* webpackChunkName: "accountDetails" */
					'../views/account/resetPassword.vue'),
				meta: {
					alive: true
				}
			},
			{
				path: '/category',
				name: 'category',
				component: () => import( /* webpackChunkName: "accountDetails" */
					'../views/category/list.vue'),
				meta: {
					alive: true
				}
			}, {
				path: '/sparesList',
				name: 'sparesList',
				component: () => import( /* webpackChunkName: "sparesList" */
					'../views/picture/sparesList.vue'),
				meta: {
					alive: true
				}
			}, {
				path: '/pictureEditing',
				name: 'pictureEditing',
				component: () => import( /* webpackChunkName: "pictureEditing" */
					'../views/picture/pictureEditing.vue'),
				meta: {
					alive: true
				}
			}, {
				path: '/priceEditing',
				name: 'priceEditing',
				component: () => import( /* webpackChunkName: "priceEditing" */
					'../views/price/priceEditing.vue')
			}, {
				path: '/modifyDetails',
				name: 'modifyDetails',
				component: () => import( /* webpackChunkName: "modifyDetails" */
					'../views/price/modifyDetails.vue'),
				meta: {
					alive: true
				}
			}, {
				path: '/articleEditing',
				name: 'articleEditing',
				component: () => import( /* webpackChunkName: "articleEditing" */
					'../views/article/articleEditing.vue'),
				meta: {
					alive: true
				}
			},
			{
				path: '/productSale',
				name: 'productSale',
				component: () => import( /* webpackChunkName: "productSale" */ '../views/productSale/list.vue'),
				meta: {
					alive: true
				}
			},
			{
				path: '/productSaleDetail',
				name: 'productSaleDetail',
				component: () => import( /* webpackChunkName: "productSaleDetail" */ '../views/productSale/detail.vue'),
				meta: {
					alive: true
				}
			}, {
			path: '/assessment',
			name: 'assessment',
			component: () => import( /* webpackChunkName: "assessment" */ '../views/assessment/assessment.vue'),
			meta: {
				alive: true
			}
		}
		]
	},
	{
		path: '/login',
		name: 'login',
		component: () => import( /* webpackChunkName: "login" */ '../views/login.vue')
	},
	{
		path: '/query',
		name: 'query',
		component: () => import( /* webpackChunkName: "query" */ '../views/query/query.vue')
	},
	{
		path: '/queryLogin',
		name: 'queryLogin',
		component: () => import( /* webpackChunkName: "queryLogin" */ '../views/query/login.vue')
	},
	{
		path: '/insureLayout',
		name: 'insureLayout',
		component: () => import( /* webpackChunkName: "insureLayout" */ '../views/insure/insureLayout.vue'),
		redirect: "/insure",
		children: [{
			path: '/insure',
			name: 'insure',
			component: () => import( /* webpackChunkName: "Home" */ '../views/insure/insure.vue')
		}, {
			path: '/insureDetails',
			name: 'insureDetails',
			component: () => import( /* webpackChunkName: "Home" */ '../views/insure/insureDetails.vue')
		}]
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
